import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allz-based-preview',
  templateUrl: './allz-based-preview.component.html',
  styleUrls: ['./allz-based-preview.component.css']
})
export class AllzBasedPreviewComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {

  }

}
