<app-e-card *ngIf="previewOne"></app-e-card>
<app-business-card *ngIf="previewTwo"></app-business-card>
<app-preview-three *ngIf="previewThree"></app-preview-three>
<app-preview-four *ngIf="previewFour"></app-preview-four>
<app-preview-five *ngIf="previewFive"></app-preview-five>
<app-preview-six *ngIf="previewSix"></app-preview-six>
<app-visiting-card *ngIf="previewSeven"></app-visiting-card>
<app-preview-eight *ngIf="previewEight"></app-preview-eight>
<app-preview-nine *ngIf="previewNine"></app-preview-nine>
<app-preview-ten *ngIf="previewTen"></app-preview-ten>
<app-preview-eleven *ngIf="previewEleven"></app-preview-eleven>
<app-preview-twelve *ngIf="previewTwelve"></app-preview-twelve>
<app-preview-thirteen *ngIf="previewThirteen"></app-preview-thirteen>
<app-preview-fourteen *ngIf="previewFourteen"></app-preview-fourteen>
<app-preview-fifteen *ngIf="previewFifteen"></app-preview-fifteen>
<app-preview-sixteen *ngIf="previewSixteen"></app-preview-sixteen>
<app-preview-seventeen *ngIf="previewSeventeen"></app-preview-seventeen>
<app-preview-eighteen *ngIf="previewEighteen"></app-preview-eighteen>
<app-preview-nineteen *ngIf="previewNineteen"></app-preview-nineteen>
<app-preview-twenty *ngIf="previewTwenty"></app-preview-twenty>
<app-preview-twenty-one *ngIf="previewTwentyOne"></app-preview-twenty-one>
<app-preview-twenty-two *ngIf="previewTwentyTwo"></app-preview-twenty-two>
<app-preview-twenty-three *ngIf="previewTwentyThree"></app-preview-twenty-three>
<app-preview-twenty-four *ngIf="previewTwentyFour"></app-preview-twenty-four>
<app-preview-twenty-five *ngIf="previewTwentyFive"></app-preview-twenty-five>
<app-preview-twenty-six *ngIf="previewTwentySix"></app-preview-twenty-six>
<app-preview-twenty-seven *ngIf="previewTwentySeven"></app-preview-twenty-seven>
<app-preview-twenty-eight *ngIf="previewTwentyEight"></app-preview-twenty-eight>
<app-preview-twenty-nine *ngIf="previewTwentyNine"></app-preview-twenty-nine>
<app-preview-thirty *ngIf="previewThirty"></app-preview-thirty>
<app-preview-thirty-one *ngIf="previewThirtyOne"></app-preview-thirty-one>
<app-preview-thirty-two *ngIf="previewThirtyTwo"></app-preview-thirty-two>
<app-preview-thirty-three *ngIf="previewThirtyThree"></app-preview-thirty-three>
<app-preview-thirty-four *ngIf="previewThirtyFour"></app-preview-thirty-four>
<app-preview-thirty-five *ngIf="previewThirtyFive"></app-preview-thirty-five>
<app-preview-thirty-six *ngIf="previewThirtySix"></app-preview-thirty-six>
<app-preview-thirty-seven *ngIf="previewThirtySeven"></app-preview-thirty-seven>
<app-preview-thirty-eight *ngIf="previewThirtyEight"></app-preview-thirty-eight>
<app-preview-thirty-nine *ngIf="previewThirtyNine"></app-preview-thirty-nine>
<app-preview-forty *ngIf="previewForty"></app-preview-forty>
<app-preview-forty-one *ngIf="previewFortyOne"></app-preview-forty-one>
<app-preview-forty-two *ngIf="previewFortyTwo"></app-preview-forty-two>
<app-preview-forty-three *ngIf="previewFortyThree"></app-preview-forty-three>
<app-preview-forty-four *ngIf="previewFortyFour"></app-preview-forty-four>
<app-preview-forty-five *ngIf="previewFortyFive"></app-preview-forty-five>
<app-preview-forty-six *ngIf="previewFortySix"></app-preview-forty-six>
<app-preview-forty-seven *ngIf="previewFortySeven"></app-preview-forty-seven>
<app-preview-forty-eight *ngIf="previewFortyEight"></app-preview-forty-eight>
<app-preview-forty-nine *ngIf="previewFortyNine"></app-preview-forty-nine>
<app-preview-fifty *ngIf="previewFifty"></app-preview-fifty>
<app-preview-fifty-one *ngIf="previewFiftyOne"></app-preview-fifty-one>
<app-preview-fifty-two *ngIf="previewFiftyTwo"></app-preview-fifty-two>
<app-preview-fifty-three *ngIf="previewFiftyThree"></app-preview-fifty-three>
<app-preview-fifty-four *ngIf="previewFiftyFour"></app-preview-fifty-four>
<app-preview-fifty-five *ngIf="previewFiftyFive"></app-preview-fifty-five>
<app-preview-fifty-six *ngIf="previewFiftySix"></app-preview-fifty-six>
<app-preview-fifty-seven *ngIf="previewFiftySeven"></app-preview-fifty-seven>
<app-preview-fifty-eight *ngIf="previewFiftyEight"></app-preview-fifty-eight>
<app-preview-fifty-nine *ngIf="previewFiftyNine"></app-preview-fifty-nine>
<app-preview-sixty *ngIf="previewSixty"></app-preview-sixty>
<app-traceFarm *ngIf="traceFarm"></app-traceFarm>
<app-vsspl *ngIf="vsspl"></app-vsspl>
<app-preview-mssi *ngIf="mssi"></app-preview-mssi>
<app-allz-based-preview *ngIf="allzPreview"></app-allz-based-preview>