import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { FormDataService } from '@services/formDataService';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ECardForm } from "../modal/eCardForm";

@Component({
    selector: 'app-main-screen',
    templateUrl: './main-screen.component.html',
    styleUrls: ['./main-screen.component.css']
})
export class MainScreenComponent implements OnInit {
    currentFormData: ECardForm | undefined;
    companySrcUrl: string = ""
    profileSrcUrl: string = ""
    profileShow = false;
    previewOne = false;
    previewTwo = false;
    previewThree = false;
    previewFour = false;
    previewFive = false;
    previewSix = false;
    previewSeven = false;
    previewEight = false;
    previewNine = false;
    previewTen = false;
    previewEleven = false;
    previewTwelve = false;
    previewThirteen = false;
    previewFourteen = false;
    previewFifteen = false;
    previewSixteen = false;
    previewSeventeen = false;
    previewEighteen = false;
    previewNineteen = false;
    previewTwenty = false;
    previewTwentyOne = false;
    previewTwentyTwo = false;
    previewTwentyThree = false;
    previewTwentyFour = false;
    previewTwentyFive = false;
    previewTwentySix = false;
    previewTwentySeven = false;
    previewTwentyEight = false;
    previewTwentyNine = false;
    previewThirty = false;
    previewThirtyOne = false;
    previewThirtyTwo = false;
    previewThirtyThree = false;
    previewThirtyFour = false;
    previewThirtyFive = false;
    previewThirtySix = false;
    previewThirtySeven = false;
    previewThirtyEight = false;
    previewThirtyNine = false;
    previewForty = false;
    previewFortyOne = false;
    previewFortyTwo = false;
    previewFortyThree = false;
    previewFortyFour = false;
    previewFortyFive = false;
    previewFortySix = false;
    previewFortySeven = false;
    previewFortyEight = false;
    previewFortyNine = false;
    previewFifty = false;
    previewFiftyOne = false;
    previewFiftyTwo = false;
    previewFiftyThree = false;
    previewFiftyFour = false;
    previewFiftyFive = false;
    previewFiftySix = false;
    previewFiftySeven = false;
    previewFiftyEight = false;
    previewFiftyNine = false;
    previewSixty = false;
    previewSixtyOne = false;
    traceFarm = false;
    vsspl = false;
    mssi = false;
    allzPreview = false;
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private dataService: FormDataService, private route: ActivatedRoute,
        private appService: AppService, private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            // const eCardName = params['eCardName'];
            const eCardName = params['CardName']
            console.log("ecard name :", eCardName)
            this.getCardData(eCardName);
        });
    }

    getCardData(eCardName: string) {
        this.appService.getCardDetails(eCardName)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.spinner.hide();
                console.log("get card data:", data)
                this.currentFormData = data;
                localStorage.setItem('eCardName', this.currentFormData.ecardName)
                if (this.currentFormData.firstName === 'ALLZ') {
                    this.allzPreview = true;
                } else {
                    if (this.currentFormData.previewType == "previewOne") {
                        this.previewOne = true;
                    } else if (this.currentFormData.previewType == "previewTwo") {
                        this.previewTwo = true;
                    } else if (this.currentFormData.previewType == "previewThree") {
                        this.previewThree = true;
                    } else if (this.currentFormData.previewType == "previewFour") {
                        this.previewFour = true;
                    } else if (this.currentFormData.previewType == "previewFive") {
                        this.previewFive = true;
                    } else if (this.currentFormData.previewType == "previewSix") {
                        this.previewSix = true;
                    } else if (this.currentFormData.previewType == "previewSeven") {
                        this.previewSeven = true;
                    } else if (this.currentFormData.previewType == "previewEight") {
                        this.previewEight = true;
                    } else if (this.currentFormData.previewType == "previewNine") {
                        this.previewNine = true;
                    } else if (this.currentFormData.previewType == "previewTen") {
                        this.previewTen = true;
                    } else if (this.currentFormData.previewType == "previewEleven") {
                        this.previewEleven = true;
                    } else if (this.currentFormData.previewType == "previewTwelve") {
                        this.previewTwelve = true;
                    } else if (this.currentFormData.previewType == "previewThirteen") {
                        this.previewThirteen = true;
                    } else if (this.currentFormData.previewType == "previewFourteen") {
                        this.previewFourteen = true;
                    } else if (this.currentFormData.previewType == "previewFifteen") {
                        this.previewFifteen = true;
                    } else if (this.currentFormData.previewType == "previewSixteen") {
                        this.previewSixteen = true;
                    } else if (this.currentFormData.previewType == "previewSeventeen") {
                        this.previewSeventeen = true;
                    } else if (this.currentFormData.previewType == "previewEighteen") {
                        this.previewEighteen = true;
                    } else if (this.currentFormData.previewType == "previewNineteen") {
                        this.previewNineteen = true;
                    } else if (this.currentFormData.previewType == "previewTwenty") {
                        this.previewTwenty = true;
                    } else if (this.currentFormData.previewType == "previewTwentyOne") {
                        this.previewTwentyOne = true;
                    } else if (this.currentFormData.previewType == "previewTwentyTwo") {
                        this.previewTwentyTwo = true;
                    } else if (this.currentFormData.previewType == "previewTwentyThree") {
                        this.previewTwentyThree = true;
                    } else if (this.currentFormData.previewType == "previewTwentyFour") {
                        this.previewTwentyFour = true;
                    } else if (this.currentFormData.previewType == "previewTwentyFive") {
                        this.previewTwentyFive = true;
                    } else if (this.currentFormData.previewType == "previewTwentySix") {
                        this.previewTwentySix = true;
                    } else if (this.currentFormData.previewType == "previewTwentySeven") {
                        this.previewTwentySeven = true;
                    } else if (this.currentFormData.previewType == "previewTwentyEight") {
                        this.previewTwentyEight = true;
                    } else if (this.currentFormData.previewType == "previewTwentyNine") {
                        this.previewTwentyNine = true;
                    } else if (this.currentFormData.previewType == "previewThirty") {
                        this.previewThirty = true;
                    } else if (this.currentFormData.previewType == "previewThirtyOne") {
                        this.previewThirtyOne = true;
                    } else if (this.currentFormData.previewType == "previewThirtyTwo") {
                        this.previewThirtyTwo = true;
                    } else if (this.currentFormData.previewType == "previewThirtyThree") {
                        this.previewThirtyThree = true;
                    } else if (this.currentFormData.previewType == "previewThirtyFour") {
                        this.previewThirtyFour = true;
                    } else if (this.currentFormData.previewType == "previewThirtyFive") {
                        this.previewThirtyFive = true;
                    } else if (this.currentFormData.previewType == "previewThirtySix") {
                        this.previewThirtySix = true;
                    } else if (this.currentFormData.previewType == "previewThirtySeven") {
                        this.previewThirtySeven = true;
                    } else if (this.currentFormData.previewType == "previewThirtyEight") {
                        this.previewThirtyEight = true;
                    } else if (this.currentFormData.previewType == "previewThirtyNine") {
                        this.previewThirtyNine = true;
                    } else if (this.currentFormData.previewType == "previewForty") {
                        this.previewForty = true;
                    } else if (this.currentFormData.previewType == "previewFortyOne") {
                        this.previewFortyOne = true;
                    } else if (this.currentFormData.previewType == "previewFortyTwo") {
                        this.previewFortyTwo = true;
                    } else if (this.currentFormData.previewType == "previewFortyThree") {
                        this.previewFortyThree = true;
                    } else if (this.currentFormData.previewType == "previewFortyFour") {
                        this.previewFortyFour = true;
                    } else if (this.currentFormData.previewType == "previewFortyFive") {
                        this.previewFortyFive = true;
                    } else if (this.currentFormData.previewType == "previewFortySix") {
                        this.previewFortySix = true;
                    } else if (this.currentFormData.previewType == "previewFortySeven") {
                        this.previewFortySeven = true;
                    } else if (this.currentFormData.previewType == "previewFortyEight") {
                        this.previewFortyEight = true;
                    } else if (this.currentFormData.previewType == "previewFortyNine") {
                        this.previewFortyNine = true;
                    } else if (this.currentFormData.previewType == "previewFifty") {
                        this.previewFifty = true;
                    } else if (this.currentFormData.previewType == "previewFiftyOne") {
                        this.previewFiftyOne = true;
                    } else if (this.currentFormData.previewType == "previewFiftyTwo") {
                        this.previewFiftyTwo = true;
                    } else if (this.currentFormData.previewType == "previewFiftyThree") {
                        this.previewFiftyThree = true;
                    } else if (this.currentFormData.previewType == "previewFiftyFour") {
                        this.previewFiftyFour = true;
                    } else if (this.currentFormData.previewType == "previewFiftyFive") {
                        this.previewFiftyFive = true;
                    } else if (this.currentFormData.previewType == "previewFiftySix") {
                        this.previewFiftySix = true;
                    } else if (this.currentFormData.previewType == "previewFiftySeven") {
                        this.previewFiftySeven = true;
                    } else if (this.currentFormData.previewType == "previewFiftyEight") {
                        this.previewFiftyEight = true;
                    } else if (this.currentFormData.previewType == "previewFiftyNine") {
                        this.previewFiftyNine = true;
                    } else if (this.currentFormData.previewType == "previewSixty") {
                        this.previewSixty = true;
                    } else if (this.currentFormData.previewType == "previewSixtyOne") {
                        this.previewSixtyOne = true;
                    } else if (this.currentFormData.previewType == "traceFarm") {
                        this.traceFarm = true;
                    } else if (this.currentFormData.previewType == "vsspl") {
                        this.vsspl = true;
                    } else if (this.currentFormData.previewType == "mssi") {
                        this.mssi = true;
                    }
                }
            }, (error: any) => {
                console.log("something went wrong:", error)
            })
    }

}
