<div class="vh-100 bg-secondary">

  <div class="container-fluid">
    <div class="row text-center py-4">
      <div class="container">
        <img class="img-fluid" src="../../../../assets/img/previewImages/Allz-business-card.jpg">
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>
<app-ecard-footer></app-ecard-footer>